/* eslint-disable no-shadow */
import { urls } from 'config';
import mapboxSettings from 'mapboxSettings';
import React, {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Map as MapItem, Layer, Source, NavigationControl } from 'react-map-gl';
import { useParams } from 'react-router-dom';
import { farms, useGetFarmsByRegionQuery, useLazyGetFieldsByRegionQuery } from 'store/slices/farms';
import { commonGet } from 'utils/api';
import { v4 as uuidv4 } from 'uuid';
import { getAreaByPolygon, getAreaTitle, getCurrentPolygon } from 'utils';
import {
  fillPaintEmpty,
  getBBoxFromMap,
  getBounds,
  getCount,
  getEstimate,
  lineLayout,
  linePaint,
} from 'utils/map';
import MapSpinner from 'views/Map/components/Map/MapSpinner';
import {
  boundsMinZoom,
  cropColors,
  emptyGeojson,
  emptyGeojsonFn,
  maxPolygonsOnMap,
} from 'views/Map/components/Map/constants';
import PopupField from 'views/Map/components/Map/PopupField';
import { useDispatch } from 'react-redux';
import ZoomButton from 'views/Map/components/Map/ZoomButton';
import debounce from 'lodash.debounce';
import { actions, initialState, reducer } from 'views/Map/components/Map/mapReducer';
import FieldsPolygons from 'views/Map/components/Map/FieldsPolygons';
import PolygonLines from 'views/Map/components/Map/PolygonLines';

const Map = ({ isShowAdditionalFields }) => {
  const params = useParams();
  const mapLink = useRef();
  const [token, setToken] = useState();
  const [popup, setPopup] = useState();
  const [state, dispatchMap] = useReducer(reducer, initialState());
  const dispatchState = useDispatch();
  const [refetch, result] = useLazyGetFieldsByRegionQuery();
  const { isLoading, data } = useGetFarmsByRegionQuery({ regionId: params.id });

  useEffect(() => {
    dispatchState(farms.util.invalidateTags([{ type: 'fields' }]));
  }, []);

  const itemInfo = useMemo(
    () => data?.find((element) => element.owner === params.owner),
    [data, params.owner]
  );

  useEffect(() => {
    refetch({
      regionId: params.id,
      owner: params.owner,
    });
  }, [params.owner, params.id]);

  useEffect(() => {
    if (!result.currentData || isLoading || !itemInfo?.field_count) {
      return;
    }

    if (result.currentData?.cursor && result.currentData?.polygons?.length < itemInfo.field_count) {
      refetch({
        regionId: params.id,
        owner: params.owner,
        cursor: result.currentData?.cursor,
      });
    }
  }, [result.currentData, isLoading, itemInfo]);

  const getToken = async () => {
    const response = await commonGet({ url: urls().mapboxToken });
    setToken(response.data?.mapbox);
  };

  useEffect(() => {
    getToken();
  }, []);

  const fieldsGeojson = useMemo(
    () =>
      result.currentData?.polygons?.length
        ? {
            type: 'FeatureCollection',
            features: (result.currentData?.polygons || []).map((polygon) => ({
              type: 'Feature',
              geometry: polygon,
              properties: {
                ...polygon?.properties,
                field_id: uuidv4(),
                cropColor: cropColors[polygon?.properties?.crop],
              },
            })),
          }
        : emptyGeojson,
    [result.currentData]
  );

  const centerMap = useMemo(
    () =>
      result.currentData?.bbox
        ? [result.currentData?.bbox.slice(0, 2), result.currentData?.bbox.slice(2)]
        : [],
    [result.currentData]
  );

  const onMove = useCallback(() => {
    setPopup();
  }, []);

  const fillOnClick = useCallback(
    (e) => {
      setPopup();
      if (!e || !result.currentData) return;

      const features = e.target.queryRenderedFeatures(e.point, {
        layers: ['Current-Fields-fill'],
      });
      const featuresAnother = e.target.queryRenderedFeatures(e.point, {
        layers: ['Fields-fill'],
      });
      const feature = features?.[0] || featuresAnother?.[0];

      if (!feature) {
        return;
      }

      const { properties } = feature || {};
      const { field_id, crop, owner, cadastral_status, cadastral_number } = properties || {};
      const currrentPolygon = features?.[0]
        ? fieldsGeojson.features.find((info) => info.properties.field_id === field_id)?.geometry ||
          []
        : getCurrentPolygon(state.polygons, field_id);

      setPopup({
        lat: e.lngLat?.lat,
        lon: e.lngLat?.lng,
        area: getAreaTitle(getAreaByPolygon(currrentPolygon), 0),
        areaHa: getAreaTitle(getAreaByPolygon(currrentPolygon), 0, 'ha'),
        crop,
        owner,
        cadastral_status,
        cadastral_number,
      });
    },
    [fieldsGeojson.features, result.currentData, state]
  );

  const onClickMap = useCallback(
    (e) => {
      dispatchMap({ type: actions.SET_LNG_LAT_CLICK, payload: e.lngLat });
      fillOnClick(e);
    },
    [fillOnClick]
  );

  const polygonsLines = (result.currentData?.polygons || []).reduce(
    (res, polygon) =>
      res.concat(
        polygon.coordinates.map((coordinates) => ({
          coordinates,
          key: `${uuidv4()}`,
        }))
      ),
    []
  );

  const polygonLinesGeojson = useMemo(() => {
    const resultJson = emptyGeojsonFn();

    resultJson.features = (polygonsLines || []).map((polygon) => ({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: polygon.coordinates,
      },
    }));
    return resultJson;
  }, [polygonsLines]);

  const scrollToLoadedZoom = useCallback(() => {
    if (mapLink.current) {
      mapLink.current.flyTo({ zoom: boundsMinZoom + 0.1, essential: true });
    }
  }, []);

  const loadEstimate = useCallback(async (currentZoom, params, bbox) => {
    if (currentZoom > boundsMinZoom) {
      dispatchMap({ type: actions.SET_WRAP_POLYGON, payload: [] });
      return null;
    }
    const estimateResponse = await getEstimate(params);

    const { hull: currentWrapPolygon, count: currentPolygonsLength } = estimateResponse.data;
    currentWrapPolygon.key = `${currentZoom}${bbox}`;
    dispatchMap({ type: actions.SET_WRAP_POLYGON, payload: [currentWrapPolygon] });

    return currentPolygonsLength;
  }, []);

  const loadBounds = useCallback(async (currentZoom, params, bbox, currentPolygonsLength) => {
    if (currentZoom < boundsMinZoom) {
      dispatchMap({ type: actions.SET_POLYGONS, payload: [] });
      return 0;
    }

    const boundsResponse = await getBounds(params);
    let { cursor, polygons: boundsPolygons } = boundsResponse.data;
    if (cursor && currentPolygonsLength > boundsPolygons.length) {
      while (
        cursor &&
        currentPolygonsLength > boundsPolygons.length &&
        boundsPolygons.length < maxPolygonsOnMap
      ) {
        const nextResponse = await getBounds({ ...params, cursor });
        cursor = nextResponse.data.cursor;
        boundsPolygons = (boundsPolygons || []).concat(nextResponse.data.polygons || []);
      }
    }

    const currentPolygons = (boundsPolygons || []).map((polygon, index) =>
      Object.assign(polygon, {
        key: `${currentZoom}${bbox}${index}`,
        field_id: `${currentZoom}${bbox}${index}`,
      })
    );
    dispatchMap({ type: actions.SET_POLYGONS, payload: currentPolygons });
    return currentPolygons.length;
  }, []);

  const loadPolygons = useCallback(
    async (map) => {
      try {
        mapLink.current = map;
        dispatchMap({ type: actions.START_LOADING });

        const currentZoom = map.getZoom();
        const currentCenter = map.getCenter();
        dispatchMap({
          type: actions.SET_MAP_INFO,
          payload: {
            zoom: currentZoom,
            mapCenter: currentCenter,
          },
        });

        const bbox = getBBoxFromMap(map);
        // eslint-disable-next-line no-shadow
        const params = { bbox: getBBoxFromMap(map) };
        let count = null;
        if (currentZoom > boundsMinZoom) {
          const response = await getCount(params);
          count = response.data?.count ?? 0;
        }

        if (count) {
          await loadEstimate(currentZoom, params, bbox);
          await loadBounds(currentZoom, params, bbox, count);
        }

        dispatchMap({
          type: actions.FINISH_LOADING,
          payload: {
            allPolygonsLength: count,
          },
        });
      } catch (e) {
        dispatchMap({ type: actions.CLEAR_ON_ERROR });
        // eslint-disable-next-line no-console
        console.error('Ошибка получения полигонов: ', e);
      }
    },
    [loadEstimate, loadBounds]
  );

  const onHoverPolygon = useCallback(
    debounce((payload) => {
      dispatchMap({ type: actions.SET_HOVERED_POLYGON, payload });
    }, 500),
    []
  );

  const onStyleLoad = useCallback(
    (event) => {
      const map = event.target;
      const cb = debounce(() => loadPolygons(map), 600);
      map.on('zoom', cb);
      map.on('move', cb);
      map.on('mousemove', 'Fields-fill', (e) => {
        map.setFilter('FieldsHover-fill', ['==', 'field_id', e.features[0].properties.field_id]);
        onHoverPolygon(e.features[0].properties.field_id);
      });
      map.on('mouseleave', 'Fields-fill', () => {
        map.setFilter('FieldsHover-fill', ['==', 'field_id', 0]);
        onHoverPolygon(null);
      });
      loadPolygons(map);
    },
    [loadPolygons]
  );

  const initialViewState = useMemo(
    () => ({
      ...(centerMap ? { bounds: centerMap } : {}),
      zoom: mapboxSettings.zoom,
    }),
    [centerMap, mapboxSettings.zoom]
  );

  const fieldsFillProperties = {
    id: 'Current-Fields-fill',
    type: 'fill',
    fillLayout: { visibility: 'visible' },
    paint: {
      ...fillPaintEmpty,
      'fill-opacity': 1,
    },
  };

  const resultPolygons = useMemo(
    () => state.polygons.filter((item) => item.properties.owner !== params.owner),
    [state.polygons, params.owner]
  );

  const fieldsFillLines = {
    id: 'Current-Fields-fill-lines',
    type: 'line',
    fillLayout: lineLayout,
    paint: {
      ...linePaint,
    },
  };

  if (
    !token ||
    result.isLoading ||
    isLoading ||
    !itemInfo?.field_count ||
    (result.currentData?.polygons || [])?.length < itemInfo?.field_count
  ) {
    return <MapSpinner />;
  }

  return (
    <MapItem
      // eslint-disable-next-line import/no-webpack-loader-syntax
      mapLib={import('!mapbox-gl')}
      mapStyle='mapbox://styles/theirix/ckh4x4ekq03bq19odb14zifzz'
      mapboxAccessToken={token}
      className='map-container__field-map'
      initialViewState={initialViewState}
      onMove={onMove}
      onClick={onClickMap}
      onLoad={onStyleLoad}
    >
      {isShowAdditionalFields && (
        <>
          <FieldsPolygons isShowCrops={state.isShowCrops} polygons={resultPolygons} opacity={0} />
          <PolygonLines polygons={resultPolygons} linePaintResult={linePaint} />
          <Suspense fallback={<></>}>
            <ZoomButton zoom={state.zoom} scrollToLoadedZoom={scrollToLoadedZoom} />
          </Suspense>
          {state.isPolygonsLoading && <MapSpinner />}
        </>
      )}

      <div className='zoom-bounds'>
        <NavigationControl showCompass={false} />
      </div>

      <Source id='fieldsGeojson' type='geojson' data={fieldsGeojson}>
        <Layer {...fieldsFillProperties} />
      </Source>
      <Source id='fieldsGeojsonLines' type='geojson' data={polygonLinesGeojson}>
        <Layer {...fieldsFillLines} />
      </Source>
      {!!popup && <PopupField popupField={popup} />}
    </MapItem>
  );
};

export default memo(Map);
