import { useCallback, useEffect, useMemo } from 'react';
import { getOutlets, getSilos } from 'utils/map';
import { clusterTypes } from '../constants';
import { actions } from '../mapReducer';

const useLoadFilters = ({ dispatch, clusterType, ...state }) => {
  const loadOutlets = useCallback(async () => {
    try {
      const response = await getOutlets();
      let { cursor, data } = response.data;
      while (cursor) {
        const nextResponse = await getOutlets({ cursor });
        cursor = nextResponse.data.cursor;
        data = (data || []).concat(nextResponse.data?.data || []);
      }
      dispatch({ type: actions.SET_OUTLETS, payload: data });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Ошибка получения аутлетов: ', e);
      dispatch({ type: actions.SET_OUTLETS, payload: [] });
    }
  }, []);

  const loadSilos = useCallback(async () => {
    try {
      const response = await getSilos();
      let { cursor, data } = response.data;
      while (cursor) {
        const nextResponse = await getSilos({ cursor });
        cursor = nextResponse.data.cursor;
        data = (data || []).concat(nextResponse.data?.data || []);
      }
      dispatch({ type: actions.SET_SILOS, payload: data });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Ошибка получения аутлетов: ', e);
      dispatch({ type: actions.SET_SILOS, payload: [] });
    }
  }, []);

  /**
   * load filters for markers
   */
  useEffect(() => {
    if (clusterType === clusterTypes.SILOS) {
      loadSilos();
    } else if (clusterType === clusterTypes.OUTLETS) {
      loadOutlets();
    }
  }, [loadSilos, loadOutlets, clusterType]);

  const outlets = useMemo(
    () =>
      (state.outlets || []).filter(({ brands, equipments }) => {
        let include = true;
        if (state.filters.brands?.value) {
          include = brands.includes(state.filters.brands.value);
        }
        if (include && state.filters.equipments?.value) {
          include = equipments.includes(state.filters.equipments?.value);
        }
        return include;
      }),
    [state.outlets, state.filters]
  );

  const silos = useMemo(
    () =>
      (state.silos || []).filter(({ kind }) => {
        let include = true;
        if (state.filters.kind?.value) {
          include = kind.includes(state.filters.kind.value);
        }
        return include;
      }),
    [state.silos, state.filters]
  );

  return { silos, outlets };
};

export default useLoadFilters;
