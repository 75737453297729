import React, { useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useStopWheel from 'hooks/useStopWheel';
import { boundsMinZoom } from '../constants';

const ZoomButton = ({ zoom, scrollToLoadedZoom }) => {
  const scrollRef = useRef(null);
  const { t } = useTranslation();
  useStopWheel(scrollRef);

  return (
    <div
      role='button'
      ref={scrollRef}
      tabIndex={0}
      className={classNames('scroll-zoom__button', {
        'scroll-zoom__button_destroy': zoom >= boundsMinZoom,
      })}
      onClick={scrollToLoadedZoom}
    >
      {t('zoomIntoFields')}
    </div>
  );
};

export default React.memo(ZoomButton);
