import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import useStopWheel from 'hooks/useStopWheel';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import HeaderAdaptive from 'views/Map/components/HeaderAdaptive';
import { getRegionList } from 'utils/map';
import Select from 'react-select';

const fontSize = '14px';

export const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 0,
    borderColor: 'transparent',
    boxShadow: 'none',
    fontSize,
    cursor: 'pointer',
    minWidth: '160px',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '600px',
  }),
  container: (styles) => ({
    ...styles,
    fontSize,
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: '#fff',
    fontSize,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: '#fff',
    fontSize,
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#fff',
    backgroundColor: ' #242C37',
    fontSize,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
    fontWeight: '700',
    fontSize: '26px',
    fontFamily: "'JetBrains Mono',Arial,sans-serif",
  }),
  IndicatorsContainer: () => ({
    display: 'none !important',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  ValueContainer: (styles) => ({
    ...styles,
    borderColor: 'transparent',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#fff',
    fontSize,
  }),
  input: (styles) => ({
    ...styles,
    color: '#fff',
    fontWeight: '700',
    fontSize: '26px',
    fontFamily: "'JetBrains Mono',Arial,sans-serif",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#fff',
    fontWeight: '700',
    fontSize: '26px',
    fontFamily: "'JetBrains Mono',Arial,sans-serif",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? '#F9FAFB' : isFocused ? '#fff' : null,
    color: isDisabled ? '#ccc' : isSelected ? '#242C37' : '#242C37',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && isSelected ? '#F9FAFB' : '#F9FAFB',
    },
    fontSize,
  }),
};

const Header = ({ selectedRegionId, toggleRegion }) => {
  const [selectedRegion, setSelectedRegionId] = useState(
    +localStorage.getItem('selectedRegionId') || 1
  );
  const [regions, setRegions] = useState([]);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  useStopWheel(scrollRef);

  const getRegionsList = async () => {
    const response = await getRegionList();
    const data = response?.data?.data || [];
    setRegions(data);
  };

  useEffect(() => {
    getRegionsList();
  }, []);

  useEffect(() => {
    if (selectedRegionId) {
      setSelectedRegionId(selectedRegionId);
    }
  }, [selectedRegionId]);

  const navigation = useMemo(
    () => [
      {
        id: uuidv4(),
        name: 'navigation.map',
        link: () => '/map',
      },
      ...(selectedRegion
        ? [
            {
              id: uuidv4(),
              name: 'navigation.summary',
              link: () => `/region/${localStorage.getItem('selectedRegionId') || 1}`,
            },
          ]
        : []),
      ...((regions || []).find((region) => region.id == selectedRegion)?.with_owners
        ? [
            {
              id: uuidv4(),
              name: 'navigation.farms',
              link: () => `/farms/${localStorage.getItem('selectedRegionId') || 1}`,
            },
          ]
        : []),
    ],
    [selectedRegion, regions]
  );

  const regionsOptions = useMemo(
    () =>
      [...regions]
        .sort((i1, i2) => i1.sort_order - i2.sort_order)
        .map((region) => ({
          value: region.id,
          label: `${region.country_short_name || ''}, ${region.name}`,
        })),
    [regions]
  );

  const regionsSelectedOption = useMemo(
    () => regionsOptions.find((region) => region.value === selectedRegion),
    [regionsOptions, selectedRegion]
  );

  const toggleRegionCb = (_selectedRegion) => {
    navigate('/map');
    setSelectedRegionId(_selectedRegion?.value);
    if (toggleRegion) {
      toggleRegion?.(_selectedRegion?.value);
    } else {
      localStorage.setItem('selectedRegionId', _selectedRegion?.value);
    }
  };

  const openLink = useCallback((nav) => {
    navigate(nav.link());
  }, []);

  return (
    <div ref={scrollRef}>
      <HeaderAdaptive isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
      <div className={classNames('map-header', isMenuActive ? 'map-header_active' : '')}>
        <div role='button' tabIndex={0} className='map-header__logo' onClick={() => navigate('/')}>
          FieldStat.ai
        </div>
        {!!navigation?.length && (
          <div className='map-header__info-wrap'>
            {navigation.map((nav) => (
              <div
                className={classNames('map-header__nav-item', {
                  'map-header__nav-item_active':
                    location.pathname.includes(nav.link()) || location.pathname === nav.link(),
                })}
                role='button'
                tabIndex={0}
                key={nav.id}
                onClick={() => openLink(nav)}
              >
                {t(nav.name)}
              </div>
            ))}
          </div>
        )}
        <div className='map-header__regions'>
          <Select
            value={regionsSelectedOption}
            name='kind'
            options={regionsOptions}
            styles={customStyles}
            onChange={toggleRegionCb}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
