import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

const propTypes = {
  cn: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
};

const defaultProps = {};

const CadasterNumberModal = ({ cn, onClose, isShow }) => {
  const copyText = useCallback(() => {
    navigator.clipboard.writeText(cn);
  }, [cn]);

  return (
    <Modal
      title='Cadaster number'
      open={isShow}
      onCancel={onClose}
      width={300}
      footer={[
        <Button key='submit' type='primary' onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <div className='marker__modal-info'>
        <span>
          <span className='marker__modal-info-text'>{cn}</span>
        </span>
        <i
          className='far fa-copy marker__modal-info-copy'
          aria-label='Copy'
          role='button'
          tabIndex={0}
          onClick={copyText}
        />
      </div>
    </Modal>
  );
};

CadasterNumberModal.propTypes = propTypes;
CadasterNumberModal.defaultProps = defaultProps;

export default React.memo(CadasterNumberModal);
