import { useEffect } from 'react';
import { getRegionsFieldsStatistic, getRegionList } from 'utils/map';
import { actions } from '../mapReducer';

const useInitRegions = ({ dispatch, selectedRegionId }) => {
  const getFieldsCount = async (regionId) => {
    try {
      const response = await getRegionsFieldsStatistic(regionId);
      dispatch({ type: actions.SET_STATISTIC, payload: response.data });
    } catch (e) {
      dispatch({ type: actions.SET_STATISTIC, payload: null });
      // eslint-disable-next-line no-console
      console.error('Ошибка получения статистики: ', e);
    }
  };

  const initRegions = async () => {
    let resultSelectedRegionId = selectedRegionId || localStorage.getItem('selectedRegionId');
    const response = await getRegionList();
    const data = response?.data?.data || [];
    dispatch({ type: actions.SET_REGIONS, payload: data });
    if (!data.find((region) => region.id === +resultSelectedRegionId)) {
      resultSelectedRegionId = data?.[0]?.id || null;
      dispatch({
        type: actions.SET_REGION_ID,
        payload: resultSelectedRegionId,
      });
    }
    return resultSelectedRegionId;
  };

  useEffect(() => {
    initRegions();
  }, []);

  useEffect(() => {
    if (selectedRegionId) {
      getFieldsCount(selectedRegionId);
    }
  }, [selectedRegionId]);
};

export default useInitRegions;
