import React, { memo, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { lineRegionWrapPaint, lineLayout } from 'utils/map';
import { emptyGeojsonFn } from '../constants';

const layerSettings = {
  id: 'wrapPolygons',
  type: 'line',
  fillLayout: lineLayout,
  paint: {
    ...lineRegionWrapPaint,
  },
};

const WrapPolygons = ({ wrapPolygon }) => {
  const wrapPolygons = useMemo(() => {
    const result = emptyGeojsonFn();

    result.features = (wrapPolygon || []).map((polygon) => ({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: polygon.coordinates[0],
      },
    }));
    return result;
  }, [wrapPolygon]);

  return (
    <Source id='wrapPolygons' type='geojson' data={wrapPolygons}>
      <Layer {...layerSettings} />
    </Source>
  );
};

export default memo(WrapPolygons);
