import React, { memo, useMemo, useRef } from 'react';
import Select from 'react-select';
import useStopWheel from 'hooks/useStopWheel';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const fontSize = '14px';

export const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    fontSize,
  }),
  container: (styles) => ({
    ...styles,
    fontSize,
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: ' #fff',
    fontSize,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: ' #fff',
    fontSize,
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#fff',
    backgroundColor: ' #242C37',
    fontSize,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#fff',
    fontSize,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? '#F9FAFB' : isFocused ? '#fff' : null,
    color: isDisabled ? '#ccc' : isSelected ? '#242C37' : '#242C37',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && isSelected ? '#F9FAFB' : '#F9FAFB',
    },
    fontSize,
  }),
};

const getOptions = (list, key) =>
  [...new Set(list.reduce((acc, item) => [...acc].concat(item[key]), []))]
    .sort((a, b) => a.localeCompare(b))
    .map((value) => ({ value, label: value }));

const Filters = ({
  className,
  outlets,
  filters,
  onChangeFilter = () => {},
  onMenuOpen = () => {},
}) => {
  const { t } = useTranslation();

  const brandsOptions = useMemo(() => getOptions(outlets, 'brands'), [outlets]);
  const equipmentOptions = useMemo(() => getOptions(outlets, 'equipments'), [outlets]);
  const scrollRef = useRef(null);
  useStopWheel(scrollRef);

  return (
    <div ref={scrollRef} className={cn('filters', className)}>
      <Select
        value={filters?.brands}
        name='brands'
        options={brandsOptions}
        placeholder={t('filters.oem')}
        className='filters__item'
        onChange={onChangeFilter}
        isClearable
        styles={customStyles}
        onMenuOpen={onMenuOpen}
      />
      <Select
        value={filters?.equipments}
        name='equipments'
        options={equipmentOptions}
        className='filters__item'
        placeholder={t('filters.equipment')}
        onChange={onChangeFilter}
        isClearable
        styles={customStyles}
        onMenuOpen={onMenuOpen}
      />
    </div>
  );
};

export default memo(Filters);
