import React, { memo, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { linePaint, lineLayout } from 'utils/map';
import { emptyGeojsonFn } from '../constants';

const PolygonLines = ({ polygons, linePaintResult }) => {
  const layerSettings = useMemo(
    () => ({
      id: 'poligonLines',
      type: 'line',
      fillLayout: lineLayout,
      paint: {
        ...(linePaintResult || linePaint || {}),
      },
    }),
    [linePaintResult]
  );

  const polygonsLines = useMemo(
    () =>
      (polygons || []).reduce(
        (res, polygon) =>
          res.concat(
            polygon.coordinates.map((coordinates, index) => ({
              coordinates,
              key: `${polygon.key}${index}`,
            }))
          ),
        []
      ),
    [polygons]
  );

  const polygonLinesGeojson = useMemo(() => {
    const result = emptyGeojsonFn();

    result.features = (polygonsLines || []).map((polygon) => ({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: polygon.coordinates,
      },
    }));
    return result;
  }, [polygonsLines]);

  return (
    <Source id='polygonLinesGeojson' type='geojson' data={polygonLinesGeojson}>
      <Layer {...layerSettings} />
    </Source>
  );
};

export default memo(PolygonLines);
