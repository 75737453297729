import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import Map from 'views/Map';
import RegionStatistic from 'views/RegionStatistic';
import Farm from 'views/Farm';
import FarmItem from 'views/FarmItem';

export const routerObject = [
  { path: '/map', element: <Map /> },
  {
    path: '/region',
    breadcrumbsTitle: 'Регион',
    children: [
      {
        path: ':id',
        children: [
          {
            index: true,
            element: <RegionStatistic />,
          },
        ],
      },
    ],
  },
  {
    path: '/farms',
    breadcrumbsTitle: 'Список пользователей',
    children: [
      {
        path: ':id',
        children: [
          {
            index: true,
            element: <Farm />,
          },
          {
            path: ':owner',
            element: <FarmItem />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/map' />,
  },
];

export const router = createBrowserRouter(routerObject);
