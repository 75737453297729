import React, { memo, useMemo } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import { emptyGeojsonFn } from '../constants';

const styles = {
  popup: {
    backgroundColor: '#1b1b1b',
    padding: 0,
    margin: 0,
    color: 'white',
    maxWidth: 232,
  },
};

const clusterLayer = {
  id: 'clustersOutlet',
  type: 'circle',
  source: 'outlets',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#2cc8f9',
    'circle-radius': 20,
  },
};

const clusterCountLayer = {
  id: 'clusterCountOutlet',
  type: 'symbol',
  source: 'outlets',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 14,
  },
  paint: {
    'text-color': '#ffffff',
  },
};

const unclusteredPointLayer = {
  id: 'unclusteredOutletPoint',
  type: 'circle',
  source: 'outlets',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#fff',
    'circle-radius': 8,
    'circle-stroke-width': 6,
    'circle-stroke-color': '#11b4da',
  },
};

const MarkersCluster = ({ outlets, popup }) => {
  const outletsGeojson = useMemo(() => {
    const result = emptyGeojsonFn();
    result.features = (outlets || []).map((outlet) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [outlet.lon, outlet.lat],
      },
      properties: { ...outlet, strObj: JSON.stringify(outlet) },
    }));
    return result;
  }, [outlets]);

  return (
    <>
      {!!outlets?.length && (
        <Source
          id='outlets'
          type='geojson'
          data={outletsGeojson}
          cluster
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      )}

      {!!popup && (
        <Popup
          anchor='top-left'
          longitude={popup.lon}
          latitude={popup.lat}
          className='marker-popup'
        >
          <div style={styles.popup}>
            <div>{popup.name}</div>
            <div>{[popup.address, popup.zip].filter((v) => !!v).join(', ')}</div>
            {!!popup.brands?.length && <div>Equipment brands: {popup.brands.join(', ')}</div>}
            {!!popup.equipments?.length && (
              <div>Equipment types: {popup.equipments.join(', ')}</div>
            )}
          </div>
        </Popup>
      )}
    </>
  );
};

export default memo(MarkersCluster);
