import React, { memo, useMemo, useRef } from 'react';
import Select from 'react-select';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import useStopWheel from 'hooks/useStopWheel';

const fontSize = '14px';

export const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    fontSize,
  }),
  container: (styles) => ({
    ...styles,
    fontSize,
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 9999,
    backgroundColor: ' #fff',
    fontSize,
  }),
  multiValue: (styles) => ({
    ...styles,
    color: '#fff',
    backgroundColor: ' #242C37',
    fontSize,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#fff',
    fontSize,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? '#F9FAFB' : isFocused ? '#fff' : null,
    color: isDisabled ? '#ccc' : isSelected ? '#242C37' : '#242C37',
    cursor: isDisabled ? 'not-allowed' : 'default',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && isSelected ? '#F9FAFB' : '#F9FAFB',
    },
    fontSize,
  }),
};

const getOptions = (list, key) =>
  [...new Set(list.reduce((acc, item) => [...acc].concat(item[key]), []))]
    .sort((a, b) => a.localeCompare(b))
    .map((value) => ({ value, label: value }));

const SilosFilter = ({
  className,
  silos,
  filters,
  onChangeFilter = () => {},
  onMenuOpen = () => {},
}) => {
  const kindOptions = useMemo(() => getOptions(silos, 'kind'), [silos]);
  const scrollRef = useRef(null);
  const { t } = useTranslation();
  useStopWheel(scrollRef);

  return (
    <div ref={scrollRef} className={cn('filters', className)}>
      <Select
        value={filters?.kind}
        name='kind'
        options={kindOptions}
        placeholder={t('filters.silos')}
        className='filters__item'
        onChange={onChangeFilter}
        isClearable
        styles={customStyles}
        onMenuOpen={onMenuOpen}
      />
    </div>
  );
};

export default memo(SilosFilter);
