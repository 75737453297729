import React, { memo, useMemo } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import { emptyGeojsonFn } from '../constants';

const styles = {
  popup: {
    backgroundColor: '#1b1b1b',
    padding: 0,
    margin: 0,
    color: 'white',
    maxWidth: 232,
  },
};

const clusterLayer = {
  id: 'clustersSilos',
  type: 'circle',
  source: 'outlets',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#2cc8f9',
    'circle-radius': 20,
  },
};

const clusterCountLayer = {
  id: 'clusterCountSilos',
  type: 'symbol',
  source: 'outlets',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 14,
  },
  paint: {
    'text-color': '#ffffff',
  },
};

const unclusteredPointLayer = {
  id: 'unclusteredSilosPoint',
  type: 'circle',
  source: 'outlets',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#fff',
    'circle-radius': 8,
    'circle-stroke-width': 6,
    'circle-stroke-color': '#11b4da',
  },
};

const MarkersCluster = ({ silos, popup }) => {
  const silosGeojson = useMemo(() => {
    const result = emptyGeojsonFn();
    result.features = (silos || []).map((s) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [s.lon, s.lat],
      },
      properties: { ...s, strObj: JSON.stringify(s) },
    }));
    return result;
  }, [silos]);

  return (
    <>
      {!!silos?.length && (
        <Source
          id='outlets'
          type='geojson'
          data={silosGeojson}
          cluster
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      )}

      {!!popup && (
        <Popup
          anchor='top-left'
          longitude={popup.lon}
          latitude={popup.lat}
          className='marker-popup'
        >
          <div style={styles.popup}>
            <div>Silos number: {popup.count}</div>
            <div>Type: {popup.kind}</div>
            {!!popup.diameters?.length && (
              <div>Diameters (in meters): {popup.diameters.join(', ')}</div>
            )}
            {!!popup.diameters?.length && (
              <div>
                Estimated storage (in tonnes):{' '}
                {popup.diameters.reduce((result, item) => result + item ** 3 * 0.5, 0)}
              </div>
            )}
          </div>
        </Popup>
      )}
    </>
  );
};

export default memo(MarkersCluster);
