import { useEffect } from 'react';

const useStopWheel = (scrollRef) => {
  const stopScroll = (e) => e.preventDefault();
  useEffect(() => {
    const scrollEl = scrollRef.current;
    scrollEl?.addEventListener('wheel', stopScroll);
    scrollEl?.addEventListener('touchmove', stopScroll);
    return () => {
      scrollEl?.removeEventListener('wheel', stopScroll);
      scrollEl?.removeEventListener('touchmove', stopScroll);
    };
  }, [scrollRef.current]);
};

export default useStopWheel;
