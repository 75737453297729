import getArea from '@turf/area';

export const getAreaByCoordinates = (coords) => getArea(coords);

export function prepareError(message, error, err = {}) {
  const errorObject = {
    error,
    err,
  };
  if (typeof message === 'object' && message !== null) {
    Object.keys(message).forEach((key) => {
      errorObject[key] = message[key];
    });
  } else {
    errorObject.error += message;
  }
  return errorObject;
}

export const isEmailValid = (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const getCurrentPolygon = (polygons, fieldId) =>
  fieldId ? polygons.find((polygon) => polygon.field_id === fieldId) : null;

export const getAreaByPolygon = (polygon) =>
  polygon ? getAreaByCoordinates(polygon) / 10000 : null;

export const getAreaTitle = (area, fixed = 2, type = 'ac') =>
  area === null ? '-' : `${(area * (type === 'ac' ? 2.471053815 : 1)).toFixed(fixed)}`;
