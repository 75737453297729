import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actions } from '../mapReducer';

const useSetDefaultSearchParams = ({ dispatch }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  /**
   * init Search Params load
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const center = params.get('center');
    const zoom = params.get('zoom');
    const additionalLayer = params.get('additionalLayer');
    const language = params.get('lang');
    const isShowCrops = params.get('isShowCrops');
    const region = params.get('region');
    if (center && zoom) {
      const centerArr = center?.split(',') || [];
      const centerResult = {
        lat: +centerArr[0],
        lng: +centerArr[1],
      };
      dispatch({
        type: actions.SET_START_PARAMS,
        payload: {
          zoom: [+zoom],
          center: centerResult,
        },
      });
    }
    if (language) {
      i18n.changeLanguage(language);
    }
    if (additionalLayer) {
      dispatch({ type: actions.SET_VIEW, payload: additionalLayer });
    }
    if (!Number(isShowCrops) && isShowCrops != null) {
      dispatch({ type: actions.TOGGLE_SHOW_CROPS });
    }
    if (additionalLayer) {
      dispatch({ type: actions.SET_VIEW, payload: additionalLayer });
    }
    if (region) {
      localStorage.setItem('selectedRegionId', +region);
      dispatch({ type: actions.SET_REGION_ID, payload: +region });
    }
    navigate(location.pathname, { replace: true });
    setIsLoaded(true);
  }, []);

  return isLoaded;
};

export default useSetDefaultSearchParams;
