import { getCurrentPolygon, getAreaByPolygon } from 'utils';
import { clusterTypes } from './constants';

export const initialState = () => ({
  wrapPolygon: [],
  isPolygonsLoading: false,
  clusterType: localStorage.getItem('clusterType') || clusterTypes.NONE,
  isShowZoomInfo: false,
  startParams: null,
  filters: {},
  polygons: [],
  cn: '',
  outlets: [],
  silos: [],
  regions: [],
  lngLat: null,
  popupField: null,
  popupMarker: null,
  isShowCrops: true,
  selectedRegionId: +localStorage.getItem('selectedRegionId') || null,
  allPolygonsLength: null,
  token: null,
  zoom: null,
  isShowCadaster: false,
  mapCenter: '',
  statistic: null,
  hoveredPolygonArea: null,
});

export const actions = {
  SET_STATISTIC: 'SET_STATISTIC',
  CLEAR_ON_ERROR: 'CLEAR_ON_ERROR',
  RESET_POPUPS: 'RESET_POPUPS',
  SET_MAP_INFO: 'SET_MAP_INFO',
  SET_FILTERS: 'SET_FILTERS',
  SET_LNG_LAT_CLICK: 'SET_LNG_LAT_CLICK',
  ADD_FILTERS: 'ADD_FILTERS',
  SET_HOVERED_POLYGON: 'SET_HOVERED_POLYGON',
  START_LOADING: 'START_LOADING',
  SET_START_PARAMS: 'SET_START_PARAMS',
  TOGGLE_SHOW_CROPS: 'TOGGLE_SHOW_CROPS',
  SET_IS_SHOW_ZOOM_PANEL: 'SET_IS_SHOW_ZOOM_PANEL',
  TOGGLE_IS_SHOW_ZOOM_PANEL: 'TOGGLE_IS_SHOW_ZOOM_PANEL',
  FINISH_LOADING: 'FINISH_LOADING',
  SET_POPUP_FIELD: 'SET_POPUP_FIELD',
  SET_WRAP_POLYGON: 'SET_WRAP_POLYGON',
  SET_POPUP_MARKER: 'SET_POPUP_MARKER',
  SET_POLYGONS: 'SET_POLYGONS',
  SET_OUTLETS: 'SET_OUTLETS',
  SET_TOKEN: 'SET_TOKEN',
  SET_REGIONS: 'SET_REGIONS',
  TOGGLE_IS_SHOW_CN: 'TOGGLE_IS_SHOW_CN',
  SET_REGION_ID: 'SET_REGION_ID',
  TOGGLE_VIEW: 'TOGGLE_VIEW',
  SET_VIEW: 'SET_VIEW',
  SET_SILOS: 'SET_SILOS',
  TOGGLE_IS_SHOW_CADASTER: 'TOGGLE_IS_SHOW_CADASTER',
};

export function reducer(state, action) {
  switch (action.type) {
    case actions.SET_VIEW:
      return {
        ...state,
        clusterType: action.payload,
      };
    case actions.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case actions.ADD_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case actions.TOGGLE_IS_SHOW_CN:
      return {
        ...state,
        cn: action.payload,
      };
    case actions.SET_LNG_LAT_CLICK:
      return {
        ...state,
        lngLat: action.payload,
      };
    case actions.TOGGLE_SHOW_CROPS:
      return {
        ...state,
        isShowCrops: !state.isShowCrops,
      };
    case actions.SET_POPUP_FIELD:
      return {
        ...state,
        popupField: action.payload,
      };
    case actions.SET_START_PARAMS:
      return {
        ...state,
        startParams: action.payload,
      };
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case actions.TOGGLE_VIEW:
      localStorage.setItem('clusterType', action.payload);
      return {
        ...state,
        clusterType: action.payload,
      };
    case actions.SET_STATISTIC:
      return {
        ...state,
        statistic: action.payload,
      };
    case actions.RESET_POPUPS:
      return {
        ...state,
        popupMarker: null,
        popupField: null,
      };
    case actions.SET_POPUP_MARKER:
      return {
        ...state,
        popupMarker: action.payload,
      };
    case actions.TOGGLE_IS_SHOW_ZOOM_PANEL: {
      return {
        ...state,
        isShowZoomInfo: !state.isShowZoomInfo,
      };
    }
    case actions.TOGGLE_IS_SHOW_CADASTER: {
      return {
        ...state,
        isShowCadaster: !state.isShowCadaster,
      };
    }
    case actions.SET_IS_SHOW_ZOOM_PANEL:
      return {
        ...state,
        isShowZoomInfo: action.payload,
      };
    case actions.SET_REGIONS: {
      return {
        ...state,
        regions: action.payload,
      };
    }
    case actions.SET_REGION_ID: {
      localStorage.setItem('selectedRegionId', action.payload);
      return {
        ...state,
        selectedRegionId: action.payload,
      };
    }
    case actions.CLEAR_ON_ERROR:
      return {
        ...state,
        isPolygonsLoading: false,
        allPolygonsLength: null,
        polygons: [],
        wrapPolygon: [],
        outlets: [],
        silos: [],
      };
    case actions.SET_HOVERED_POLYGON: {
      const currentPolygon = getCurrentPolygon(state.polygons, action.payload);
      return {
        ...state,
        hoveredPolygonArea: getAreaByPolygon(currentPolygon),
      };
    }
    case actions.START_LOADING:
      return {
        ...state,
        isPolygonsLoading: true,
        allPolygonsLength: null,
        polygons: [],
        wrapPolygon: [],
      };
    case actions.FINISH_LOADING:
      return {
        ...state,
        isPolygonsLoading: false,
        allPolygonsLength: action.payload.allPolygonsLength,
      };
    case actions.SET_POLYGONS:
      return {
        ...state,
        polygons: action.payload,
      };
    case actions.SET_OUTLETS:
      return {
        ...state,
        outlets: action.payload,
      };
    case actions.SET_SILOS:
      return {
        ...state,
        silos: action.payload,
      };
    case actions.SET_WRAP_POLYGON:
      return {
        ...state,
        wrapPolygon: action.payload,
      };
    case actions.SET_MAP_INFO:
      return {
        ...state,
        zoom: action.payload.zoom,
        mapCenter: `${action.payload.mapCenter?.lat.toFixed(3) ?? 0}, ${
          action.payload.mapCenter?.lng.toFixed(3) ?? 0
        }`,
      };
    default:
      return state;
  }
}
