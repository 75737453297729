import React, { useRef } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useStopWheel from 'hooks/useStopWheel';

const HeaderLoader = () => {
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  useStopWheel(scrollRef);

  return (
    <div ref={scrollRef}>
      <div className={classNames('map-header')}>
        <div role='button' tabIndex={0} className='map-header__logo' onClick={() => navigate('/')}>
          FieldStat.ai
        </div>
        <div className='map-header__info-wrap' />
        <div />
      </div>
    </div>
  );
};

export default React.memo(HeaderLoader);
