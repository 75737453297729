import axios from 'axios';
import http from 'axios/lib/adapters/http';
import xhr from 'axios/lib/adapters/xhr';
import { prepareError } from 'utils';

export const CUSTOM_ERROR_CODES = {
  REPORT_EMPTY: 'ReportEmpty',
  REPORT_BAD_PARAMS: 'ReportBadParams',
  CANCELED: 'CANCELED',
};

// actions names map. only for redux actions api
export const axiosEvents = new Map();

const updateEvents = (eventType) => {
  const source = axios.CancelToken.source();
  if (eventType) {
    const event = axiosEvents.get(eventType);
    if (event) {
      axiosEvents.set(eventType, { source, count: event.count + 1 });
      if (event.source) {
        event.source.cancel();
      }
    } else {
      axiosEvents.set(eventType, { source, count: 1 });
    }
  }
  return source;
};

const decEvent = (eventType) => {
  const event = axiosEvents.get(eventType);
  if (event) {
    if (event.count === 1) {
      axiosEvents.delete(eventType);
    } else {
      axiosEvents.set(eventType, { ...event, count: event.count - 1 });
    }
  }
};

const processResponseErrorData = ({ code, message }) => {
  switch (code) {
    default:
      return message;
  }
};

export const commonRequest = async ({
  headers: customHeaders,
  errorMessage,
  eventType,
  ...params
}) => {
  // Canceling tokens as of now is only enabled through saveData
  // since only there eventType is passed down to commonRequest
  const source = updateEvents(eventType);
  const headers = customHeaders || {};

  return axios({
    headers,
    ...params,
    // we need to use xhr adapter to bypass FormData in jest
    // https://stackoverflow.com/a/57138333/1018686
    adapter: params.data instanceof FormData ? xhr : http,
    cancelToken: source && source.token,
  })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { error: CUSTOM_ERROR_CODES.CANCELED };
      }
      if (error.response) {
        const err = new Error('Request failed');
        const message = processResponseErrorData(error.response.data);
        err.response = {
          status: error.response.status,
          url: error.response.config.url,
          method: error.response.config.method,
          message,
        };
        throw prepareError(message, errorMessage, err.response);
      } else if (error.message === 'Network Error') {
        throw prepareError(error.request, 'Ошибка сети');
      } else if (error.request) {
        throw prepareError(error.request, errorMessage);
      } else {
        throw prepareError(error.message, errorMessage);
      }
    })
    .finally(() => {
      decEvent(eventType);
    });
};

export const commonGet = ({ name = 'данные', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось получить ${name}`,
    method: 'GET',
  });

export const commonPost = ({ name = 'объект', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось создать ${name}`,
    method: 'POST',
  });

export const commonPut = ({ name = 'данные', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось обновить ${name}`,
    method: 'PUT',
  });

export const commonDelete = ({ name = 'данные', errorMessage, ...params }) =>
  commonRequest({
    ...params,
    errorMessage: errorMessage || `Не удалось удалить ${name}`,
    method: 'DELETE',
  });
