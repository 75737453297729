import React, { memo, useMemo } from 'react';
import { lineRegionWrapPaint, lineLayout } from 'utils/map';
import { Layer, Source } from 'react-map-gl';
import { emptyGeojsonFn } from '../constants';

const layerSettings = {
  id: 'Wrapper',
  type: 'line',
  fillLayout: lineLayout,
  paint: {
    ...lineRegionWrapPaint,
  },
};

const SelectedRegionWrapperLayer = ({ regions, selectedRegionId }) => {
  const selectedRegionGeojson = useMemo(() => {
    const selectedRegion = (regions || []).find((region) => +region.id === +selectedRegionId) || {};

    const result = emptyGeojsonFn();
    if (selectedRegion.geometry) {
      result.features = [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: selectedRegion.geometry.coordinates[0],
          },
        },
      ];
      return result;
    }

    return null;
  }, [regions, selectedRegionId]);

  if (!selectedRegionGeojson) {
    return null;
  }
  return (
    <Source id='selectedRegionGeojson' type='geojson' data={selectedRegionGeojson}>
      <Layer {...layerSettings} />
    </Source>
  );
};

export default memo(SelectedRegionWrapperLayer);
