import React from 'react';
import classNames from 'classnames';

const HeaderAdaptive = ({ isMenuActive, setIsMenuActive }) => (
  <div
    tabIndex={0}
    role='button'
    className='header-adaptive'
    onClick={() => setIsMenuActive(!isMenuActive)}
  >
    <div className={classNames('burger', isMenuActive ? 'open' : '')}>
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
);

export default React.memo(HeaderAdaptive);
