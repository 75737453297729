import React, { memo, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useGetFarmsByRegionQuery, useGetFarmsStatsByRegionQuery } from 'store/slices/farms';
import MapSpinner from 'views/Map/components/Map/MapSpinner';
import { PieChart, Pie, Cell } from 'recharts';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { cropColors } from 'views/Map/components/Map/constants';
import { convertMeters } from 'utils/convertMeters';

const OwnerInfo = ({ toggleFields, isShowAdditionalFields }) => {
  const params = useParams();
  const { t } = useTranslation();
  const { isLoading, data } = useGetFarmsByRegionQuery({ regionId: params.id });

  const { isLoading: isLoadingStats, data: dataStats } = useGetFarmsStatsByRegionQuery({
    regionId: params.id,
  });

  const popularCulturesNames = useMemo(() => {
    if (dataStats?.per_crop) {
      return Object.entries(dataStats.per_crop)
        .sort(([, value], [, value2]) => value2.area - value.area)
        .reduce((result, [key]) => {
          if (result.length < 6) {
            return result.concat(key);
          }
          return result;
        }, []);
    }
    return [];
  }, [dataStats]);

  const ownerInfo = useMemo(
    () => (data || []).find((item) => item.owner === params.owner),
    [params.owner, data]
  );

  const pieData = useMemo(
    () =>
      popularCulturesNames.reduce(
        (result, key) =>
          result.concat(
            ownerInfo?.[key]
              ? {
                  name: key,
                  value: ownerInfo[key],
                }
              : []
          ),
        []
      ),
    [ownerInfo, popularCulturesNames]
  );

  if (isLoading || isLoadingStats) {
    return <MapSpinner />;
  }

  if (!ownerInfo) {
    return <Navigate to={`/farms/${params.id}"`} />;
  }

  return (
    <div>
      <h1 className='farm-item__header'>{ownerInfo.owner}</h1>
      <div className='farm-item__field-info-wrap'>
        <div className='farm-item__field-info'>
          <div>
            {convertMeters(ownerInfo.area)} {t('ha')}
          </div>
          <div>{t('farmTableColumns.totalCropland')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>{ownerInfo.field_count}</div>
          <div>{t('farmItem.fields')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>
            {Math.floor(convertMeters(ownerInfo.area) / ownerInfo.field_count)} {t('ha')}
          </div>
          <div>{t('farmItem.averageFieldSize')}</div>
        </div>
      </div>
      <div className='farm-item__pie'>
        <PieChart width={300} height={300}>
          <Pie
            data={pieData}
            dataKey='value'
            nameKey='name'
            cx='50%'
            cy='50%'
            outerRadius={100}
            fill='#8884d8'
          >
            {pieData.map((entry) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={cropColors[entry.name]?.replace('0.7', '1') || 'rgba(255, 255, 255, 1)'}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className='farm-item__pie-info-wrap'>
        {pieData.map((entry) => (
          <div className='farm-item__pie-info-wrap' key={entry.name}>
            <div
              className='farm-item__pie-info-color'
              style={{
                background: cropColors[entry.name] || 'rgba(255, 255, 255, 0.7)',
              }}
            />
            <div className='farm-item__pie-info-text'>
              {t(`farmTableColumns.${entry.name}`, entry.name)}: {convertMeters(entry.value)}{' '}
              {t('ha')}
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{ marginTop: '24px', cursor: 'pointer' }}
          className={classNames('copy-link')}
          tabIndex={0}
          role='button'
          onClick={toggleFields}
        >
          {isShowAdditionalFields
            ? t('farmItem.hideAdditionalFilters')
            : t('farmItem.showAdditionalFilters')}
        </div>
      </div>
    </div>
  );
};

export default memo(OwnerInfo);
