import React, { memo, useMemo, useState } from 'react';
import { Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetFarmsByRegionQuery, useGetFarmsStatsByRegionQuery } from 'store/slices/farms';
import { useTranslation } from 'react-i18next';
import { convertMeters } from 'utils/convertMeters';

const TableComponent = () => {
  const params = useParams();
  const [ref, setRef] = useState();
  const { t, i18n } = useTranslation();
  const { isLoading, data } = useGetFarmsByRegionQuery({ regionId: params.id });
  const { isLoading: isLoadingStats, data: dataStats } = useGetFarmsStatsByRegionQuery({
    regionId: params.id,
  });

  const popularCulturesNames = useMemo(() => {
    if (dataStats?.per_crop) {
      return Object.entries(dataStats.per_crop)
        .sort(([, value], [, value2]) => value2.area - value.area)
        .reduce((result, [key]) => {
          if (result.length < 6) {
            return result.concat(key);
          }
          return result;
        }, [])
        .sort((key1, key2) => {
          if (key1 === 'Other') return 1; // Move 'Other' to the end
          if (key2 === 'Other') return -1; // Move 'Other' to the end
          return 0;
        });
    }
    return [];
  }, [dataStats]);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'owner',
        key: 'owner',
        // sorter: (a, b) => (a.owner > b.owner ? -1 : 1),
        width: '28%',
        title: () => <div className='farm-page__title-inactive'>{t('farmTableColumns.farm')}</div>,
        render: (text) => (
          <Link
            className='farm-page__link'
            to={`/farms/${localStorage.getItem('selectedRegionId') || 1}/${text}`}
          >
            {text}
          </Link>
        ),
      },
      {
        dataIndex: 'area',
        sorter: (a, b) => (a.area || 0) - (b.area || 0),
        defaultSortOrder: 'descend',
        title: (info) => (
          <div
            className={
              info?.sortColumns?.[0]?.column?.key === 'area' && info?.sortColumns?.[0]?.order
                ? ''
                : 'farm-page__title-inactive'
            }
          >
            {t('farmTableColumns.totalCropland')}, {t('ha')}
          </div>
        ),
        key: 'area',
        render: (text) => (
          <div className='farm-page__number'>{text ? convertMeters(text) : '0'}</div>
        ),
      },
      ...popularCulturesNames.map((key) => ({
        dataIndex: key,
        sorter: (a, b) => (a[key] || 0) - (b[key] || 0),
        title: (info) => (
          <div
            className={
              info?.sortColumns?.[0]?.column?.key === key && info?.sortColumns?.[0]?.order
                ? ''
                : 'farm-page__title-inactive'
            }
          >
            {t(`farmTableColumns.${`${key}`}`, key)}, {t('ha')}
          </div>
        ),
        key,
        render: (text) => (
          <div className='farm-page__number'>{text ? convertMeters(text) : '0'}</div>
        ),
      })),
    ],
    [i18n.language, popularCulturesNames]
  );

  return (
    <div className='farm-page' ref={setRef}>
      <Table
        loading={isLoading || isLoadingStats}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ y: window.innerHeight - (ref?.offsetTop || 0) - 110 }}
      />
    </div>
  );
};

export default memo(TableComponent);
