import { useEffect } from 'react';
import { notification } from 'antd';
import { actions } from '../mapReducer';

const useTogleRosreestr = ({ isShowCadaster, map, lngLat, dispatch }) => {
  const [api, contextHolder] = notification.useNotification();

  const showRosreesrtLayer = () => {
    if (!map?.getSource?.('rosreestr')) {
      map.addSource('rosreestr', {
        type: 'raster',
        tiles: [
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer/export?layers=show%3A27%2C24%2C23%2C22&dpi=100&format=PNG32&bboxSR=102100&imageSR=102100&size=1024%2C1024&transparent=true&f=image&_ts=false&BBOX={bbox-epsg-3857}&CRS=EPSG:3857',
        ],
        tileSize: 1024,
        minzoom: 13,
      });
    }
    if (!map?.getLayer?.('rosreestr-layer')) {
      map.addLayer({
        id: 'rosreestr-layer',
        type: 'raster',
        source: 'rosreestr',
        paint: {},
      });
    }
  };

  const hideRosreesrtLayer = () => {
    if (map?.getLayer?.('rosreestr-layer')) {
      map.removeLayer('rosreestr-layer');
    }

    if (map?.getSource?.('rosreestr')) {
      map.removeSource('rosreestr');
    }
  };

  const showCN = (payload) => {
    dispatch({ type: actions.TOGGLE_IS_SHOW_CN, payload });
  };

  const openCN = async (lngLatArg) => {
    try {
      const response = await fetch(
        `https://pkk.rosreestr.ru/api/features/?text=${lngLatArg.lat} ${lngLatArg.lng}&tolerance=32&types=[1]`
      );

      if (response.ok) {
        const json = await response.json();
        const cn = json?.results?.[0]?.attrs.cn;
        if (!cn) {
          api.error({
            message: 'No cadaster number',
            description: '',
          });
        } else {
          showCN(cn);
        }
      } else {
        api.error({
          message: 'Error get cadaster number',
          description: '',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      api.error({
        message: 'Error get cadaster number',
        description: '',
      });
    }
  };

  useEffect(() => {
    if (isShowCadaster) {
      showRosreesrtLayer();
    } else {
      hideRosreesrtLayer();
    }
  }, [isShowCadaster]);

  useEffect(() => {
    if (isShowCadaster) {
      openCN(lngLat);
    }
  }, [lngLat]);

  return { contextHolder };
};

export { useTogleRosreestr };
