import React, { Suspense } from 'react';
import Header from 'views/Map/components/Header';
import HeaderLoader from 'views/Map/components/HeaderLoader';
import OwnerInfo from './components/OwnerInfo';

const RegionStatistic = () => (
  <div className='main-page'>
    <Suspense fallback={<HeaderLoader />}>
      <Header />
    </Suspense>
    <div className='farm-item__wrap'>
      <div className='farm-item__info'>
        <Suspense fallback={<></>}>
          <OwnerInfo />
        </Suspense>
      </div>
    </div>
  </div>
);

export default RegionStatistic;
