import { commonGet } from 'utils/api';
import { urls } from 'config';

export const fitBoundsOptions = {
  duration: 0,
  linear: false,
};

export const lineLayout = {
  'line-cap': 'round',
  'line-join': 'round',
  visibility: 'visible',
};

export const linePaintLight = {
  'line-color': '#cbf4f4',
  'line-width': 2,
};

export const linePaint = {
  'line-color': '#00FFFF',
  'line-width': 2,
};

export const fillPaint = {
  'fill-color': '#00FFFF',
  'fill-opacity': 0,
};

export const fillPaintEmpty = {
  'fill-color': ['get', 'cropColor'],
  'fill-opacity': 1,
};

export const lineWrapPaint = {
  'line-color': '#ff0000',
  'line-width': 2,
  'line-dasharray': [5, 5],
};

export const lineRegionWrapPaint = {
  'line-color': '#00FFFF',
  'line-width': 2,
};

export const getBBoxFromMap = (map) => {
  if (!map) {
    return '';
  }

  const { _sw, _ne } = map?.getBounds() || {};
  return `${_sw.lng.toFixed(4)},${_sw.lat.toFixed(4)},${_ne.lng.toFixed(4)},${_ne.lat.toFixed(4)}`;
};

export const formFeatureCollection = (polygons) => ({
  type: 'FeatureCollection',
  features: (polygons || []).map((polygon) => ({
    type: 'Feature',
    properties: {},
    geometry: {
      type: polygon?.type || 'Polygon',
      coordinates: polygon?.coordinates || [],
    },
  })),
});

export const getFieldsStatistic = () =>
  commonGet({
    url: urls().statistic,
    errorMessage: 'Ошибка запроса',
  });

export const getRegionsFieldsStatistic = (regionId) =>
  commonGet({
    url: `${urls().regionsList}/${regionId}/stat`,
    errorMessage: 'Ошибка запроса',
    eventType: 'getRegionsFieldsStatistic',
  });

export const getEstimate = (params) =>
  commonGet({
    url: `${urls().regionsList}/${localStorage.getItem('selectedRegionId')}/estimate`,
    errorMessage: 'Ошибка запроса',
    params,
  });

export const getCount = (params) =>
  commonGet({
    url: `${urls().regionsList}/${localStorage.getItem('selectedRegionId')}/count`,
    errorMessage: 'Ошибка запроса',
    params,
  });

export const getBounds = (params) =>
  commonGet({
    url: `${urls().regionsList}/${localStorage.getItem('selectedRegionId')}/bounds`,
    errorMessage: 'Ошибка запроса',
    params,
  });

export const getOutlets = (params) =>
  commonGet({
    url: urls().outlets,
    errorMessage: 'Ошибка запроса',
    params,
  });

export const getSilos = (params) =>
  commonGet({
    url: urls().silos,
    errorMessage: 'Ошибка запроса',
    params,
  });

export const getRegionList = (params) =>
  commonGet({
    url: urls().regionsList,
    errorMessage: 'Ошибка запроса',
    params,
  });
