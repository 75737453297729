import { useEffect, useCallback } from 'react';
import { commonGet } from 'utils/api';
import { urls } from 'config';
import { actions } from '../mapReducer';

const useGetToken = ({ dispatch }) => {
  const getToken = useCallback(async () => {
    try {
      const response = await commonGet({ url: urls().mapboxToken });
      dispatch({ type: actions.SET_TOKEN, payload: response.data?.mapbox });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Ошибка получения token карты');
    }
  }, []);

  useEffect(() => {
    getToken();
  }, []);
};

export default useGetToken;
