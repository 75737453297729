import React, { useMemo } from 'react';
import { Popup } from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styles } from '../constants';

const PopupField = ({ popupField }) => {
  const { t } = useTranslation();

  const crop = useMemo(() => {
    const cropBase = t(`farmTableColumns.${popupField.crop}`, popupField.crop);
    const cropAdditional = popupField.crop_original
      ? t(`farmTableColumns.${popupField.crop_original}`, popupField.crop_original)
      : null;

    return cropAdditional && cropAdditional !== cropBase
      ? `${cropBase} (${cropAdditional})`
      : cropBase;
  }, [popupField]);

  return (
    <Popup
      anchor='top-left'
      longitude={popupField.lon}
      latitude={popupField.lat}
      className='marker-popup'
    >
      <div style={styles.popup}>
        <div>{crop}</div>
        <div>
          {popupField.area} {t('ac')} / {popupField.areaHa} {t('ha')}
        </div>
        {!!popupField.owner && (
          <>
            <span>{t('popup.farm')}: </span>
            <Link
              className='farm-page__link farm-page__link_white'
              to={`/farms/${localStorage.getItem('selectedRegionId') || 1}/${popupField.owner}`}
            >
              {popupField.owner || '-'}
            </Link>
          </>
        )}
        {!!popupField.cadastral_status && (
          <div>
            {t('cadastralStatus')}: {popupField.cadastral_status || '-'}
          </div>
        )}
        {!!popupField.cadastral_number && (
          <div>
            {t('cadastralNumber')}: {popupField.cadastral_number || '-'}
          </div>
        )}
      </div>
    </Popup>
  );
};

export default React.memo(PopupField);
