import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { router } from 'routes';
import { env_commit, NODE_ENV } from 'env';
import { Provider as StoreProvider } from 'react-redux';
import 'styles/index.scss';

import 'moment/locale/ru';
import './polyfills';
import './i18n';
import { RouterProvider } from 'react-router-dom';
import { store } from 'store';

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://60f005190da048429d2ca1653632a938@o586667.ingest.sentry.io/5754005',
    release: env_commit || 'localhost',
    ignoreErrors: [
      'Network request failed',
      'Network Error',
      'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
      'NetworkError when attempting to fetch resource.',
      'Failed to fetch',
      'Request aborted',
      'Unauthorized access',
      'Submit Validation Failed',
      'Request failed with status code',
      'Request failed',
      'timeout of 0ms exceeded',
    ],
  });
}

const rootElement = document.getElementById('root');

const renderApp = () => (
  <React.StrictMode>
    <StoreProvider store={store}>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
);

ReactDOM.render(renderApp(), rootElement);
