import React, { Suspense, useCallback, useReducer } from 'react';
import Header from 'views/Map/components/Header';
import Map from 'views/Map/components/Map';
import HeaderLoader from './components/HeaderLoader';
import { actions, initialState, reducer } from './components/Map/mapReducer';

const MapView = () => {
  const [state, dispatch] = useReducer(reducer, initialState());

  const toggleRegion = useCallback((payload) => {
    dispatch({ type: actions.SET_REGION_ID, payload });
  }, []);

  return (
    <div className='map-page'>
      <Suspense fallback={<HeaderLoader />}>
        <Header selectedRegionId={state.selectedRegionId} toggleRegion={toggleRegion} />
      </Suspense>
      <Suspense fallback={<></>}>
        <Map dispatch={dispatch} state={state} />
      </Suspense>
    </div>
  );
};

export default MapView;
