import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { urls } from 'config';

export const farms = createApi({
  reducerPath: 'farms',
  tagTypes: ['farms', 'fields', 'cropstat', 'regionstat'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getFarmsByRegion: build.query({
      query: (queryArg) => ({
        url: urls().owners.replace('{region_id}', queryArg.regionId),
        params: {},
      }),
      providesTags: (result, error, arg) => [{ type: 'farms', id: arg.regionId }],
      transformResponse: (response) =>
        (response?.data || []).map((item) => ({
          ...item,
          key: item.owner,
          ...Object.entries(item.per_crop).reduce(
            (result, [key, value]) => Object.assign(result, { [key]: value.area }),
            {}
          ),
        })),
    }),
    getFarmsStatsByRegion: build.query({
      query: (queryArg) => ({
        url: urls().cropstat.replace('{region_id}', queryArg.regionId),
        params: {},
      }),
      providesTags: (result, error, arg) => [{ type: 'cropstat', id: arg.regionId }],
    }),
    getRegionStats: build.query({
      query: (queryArg) => ({
        url: urls().regionsStats.replace('{region_id}', queryArg.regionId),
        params: {},
      }),
      providesTags: (result, error, arg) => [{ type: 'regionstat', id: arg.regionId }],
    }),
    getFieldsByRegion: build.query({
      query: (queryArg) => ({
        url: urls().ownersBounds.replace('{region_id}', queryArg.regionId),
        params: { owner: queryArg.owner, ...(queryArg.cursor ? { cursor: queryArg.cursor } : {}) },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName + queryArgs.owner,
      providesTags: () => [{ type: 'fields' }],
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        // eslint-disable-next-line no-param-reassign
        currentCache.polygons = (currentCache.polygons || []).concat(newItems.polygons || []);
        // eslint-disable-next-line no-param-reassign
        currentCache.cursor = newItems.cursor;
      },
    }),
  }),
});

export const {
  useGetFarmsByRegionQuery,
  useGetFieldsByRegionQuery,
  useLazyGetFieldsByRegionQuery,
  useGetFarmsStatsByRegionQuery,
  useGetRegionStatsQuery,
} = farms;
