import React, { Suspense } from 'react';
import Header from 'views/Map/components/Header';
import Table from './components/Table';

const Farm = () => (
  <div className='main-page'>
    <Suspense fallback={<></>}>
      <Header />
      <Table />
    </Suspense>
  </div>
);

export default Farm;
